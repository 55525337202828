import { configureStore } from '@reduxjs/toolkit';
import { Theme } from 'common/types';
import Futures from './reducer/futures';
import Bot, { IBotStore } from './reducer/bot';
import Loader from './reducer/loader';
import Profile from './reducer/profile';
import ThemeProvider from './reducer/them';
import { IFuturesStore } from './types';

export default configureStore({
	reducer: {
		ThemeProvider: ThemeProvider,
		Profile: Profile,
		Loader: Loader,
		Futures: Futures,
		Bot: Bot,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

interface IReduxState {
	Futures: IFuturesStore;
	Bot: IBotStore;
	ThemeProvider: {
		theme: Theme;
		themeCache: Theme;
	};
	Profile: {
		isLogin: boolean;
		haveTicket: number;
		email: any;
		phoneNumber: any;
		vipLevelName: any;
		corporationKyc: any;
		advanceKyc: any;
		basicKyc: any;
		referralType: string;
	};
}

export type { IReduxState };
