function maskEmail(email: string) {
	const skipFirstChars = 3;
	const firstThreeChar = email.slice(0, skipFirstChars);

	const domainIndexStart = email.lastIndexOf('@');
	let maskedEmail = email.slice(skipFirstChars, domainIndexStart - 2);
	maskedEmail = '****';
	const domainPlusPreviousChar = email.slice(domainIndexStart - 2, email.length);

	return firstThreeChar.concat(maskedEmail).concat(domainPlusPreviousChar);
}

export default maskEmail;
