import * as Sentry from '@sentry/react';
import 'assets/css/general/fontAwsome.css';
import 'cly-uikit/css/font/product-sans.min.css';
import 'cly-uikit/css/global.min.css';
import 'common/global.css';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import Store from 'repository/store';
import envResolver from 'utils/env-resolver';
import App from './App';
import './i18n';

Sentry.init({
	dsn: 'https://4f2d81890aad96a46d5cf455e11323fb@o4506585300008960.ingest.sentry.io/4506609572708352',
	...(process.env.NODE_ENV !== 'development' && {
		release: 'cly-front-futures@' + envResolver.VERSION,
	}),
	tunnel: 'https://apm.coinlocally.com/tunnel',
	beforeSend(event) {
		if (event.exception && event.exception.values) {
			for (const item of event.exception.values) {
				if (item.value?.includes('Request') && item.value.includes('status code')) {
					return null;
				}
			}
		}
		return event;
	},
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.BrowserTracing({ tracingOrigins: ['*'], traceXHR: false, traceFetch: false }),
		new Sentry.Replay(),
		new Sentry.Breadcrumbs({ console: false, xhr: false, fetch: false }),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['localhost', /^/],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={Store}>
		<App />
	</Provider>,
);

export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL,
} as any);
