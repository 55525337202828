import styled from '@emotion/styled';

export const LoaderTMP = styled.div(props => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100vh',
	position: 'fixed',
	top: '0',
	zIndex: '9999999',
	backgroundColor: props.theme.mode === 'light' ? '#fff' : '#101014',
}));
