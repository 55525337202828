import { Navbar } from '@coinlocally/cly-core';
import { GETGeoLocation } from 'api/edge';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LogoutAPI } from 'repository/api/call/data/navbar/auth';
import { getAssets, viewNotification } from 'repository/api/call/data/navbar/navbar';
import { GetAnnouncement, getNotification } from 'repository/api/call/data/navbar/notification';
import { changePreferencesVisibility } from 'repository/store/reducer/futures';
import { IsLogin } from 'repository/store/reducer/profile';
import { themProvider, themeCashProvider } from 'repository/store/reducer/them';
import { useAppSelector } from 'utils/hooks/redux-helper';
import maskEmail from 'utils/mask-email';
import maskPhone from 'utils/mask-phone';
import requestHandler from 'utils/requestHandler';

const CustomNavbar = () => {
	const dispatch = useDispatch();

	const theme = useAppSelector(state => state.ThemeProvider.theme);
	const language = useTranslation().i18n.language;
	const { isLogin, email, phoneNumber, vipLevelName, corporationKyc, advanceKyc, basicKyc, referralType } = useAppSelector(
		state => state.Profile,
	);

	const [notification, setNotification] = useState<null | any[]>(null);
	const [announcement, setAnnouncement] = useState<null | any[]>(null);
	const [buyCryptoAssets, setBuyCryptoAssets] = useState<{ name: string; icon: string }[]>([]);

	const [excludeNavIds, setExcludeNavIds] = useState<string[]>(['navbar_forex', 'mobile_navbar_forex']);

	useEffect(() => {
		if (isLogin) {
			getNotification().then(res => {
				setNotification(res.result);
			});
		}

		GetAnnouncement().then(res => {
			setAnnouncement(res);
		});
		getAssets().then(res => {
			const assets = res.fiat.map((fiat: any) => {
				return {
					name: fiat,
					icon: res.config.icons[fiat].png,
				};
			});
			setBuyCryptoAssets(assets);
		});

		GETGeoLocation().then(res => {
			if (res.Country !== 'AM') {
				setExcludeNavIds([]);
			}
		});
	}, []);

	const changeTheme = () => {
		dispatch(themProvider(theme === 'light' ? 'dark' : 'light'));
		dispatch(themeCashProvider(theme === 'light' ? 'dark' : 'light'));
	};

	const onNotificationSeenHandler = (notificationId: number) => {
		if (notification) {
			viewNotification(notificationId);

			const notifications = [...notification];
			const index = notifications.findIndex(item => item.id === notificationId);
			if (index !== -1) {
				notifications[index].viewed = true;
				setNotification(notifications);
			}
		}
	};

	const onSettingClickHandler = () => {
		dispatch(changePreferencesVisibility(true));
	};

	const LogOutClickHandler = async () => {
		await LogoutAPI();
		dispatch(IsLogin(false));
		requestHandler.clearHeader();
		localStorage.removeItem('refresh_auth_token');
		localStorage.removeItem('access_auth_token');
		window.location.reload();
	};

	const profileData: {
		user: string;
		kyc: {
			isVerified: boolean;
			kycLevel: 'Basic' | 'Advanced' | 'Corporation';
		};
		vipLevel: string;
	} | null = !isLogin
		? null
		: {
				kyc: {
					isVerified:
						corporationKyc?.status === 'CONFIRMED' || advanceKyc?.status === 'CONFIRMED' || basicKyc?.status === 'CONFIRMED',
					kycLevel:
						corporationKyc?.status === 'CONFIRMED' ? 'Corporation' : advanceKyc?.status === 'CONFIRMED' ? 'Advanced' : 'Basic',
				},
				user: email ? maskEmail(email) : maskPhone(phoneNumber!),
				vipLevel: vipLevelName ?? '',
		  };

	return (
		<Navbar
			theme={theme}
			changeTheme={changeTheme}
			isLinkExternal={true}
			isLogin={isLogin}
			language={language}
			buyCryptoCurrencies={buyCryptoAssets}
			announcements={announcement}
			notifications={notification}
			profile={profileData}
			affiliateSupport={{
				email: 'affiliates@coinlocally.com',
				telegramID: '',
			}}
			excludeItemIds={excludeNavIds}
			onNotificationSeenHandler={onNotificationSeenHandler}
			onLogOutClickHandler={LogOutClickHandler}
			referralType={referralType}
			onSettingClickHandler={onSettingClickHandler}
			type='FULL'
		/>
	);
};

export default CustomNavbar;
