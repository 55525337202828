import Decimal from 'decimal.js';

class Finance {
	toFixedDown(value: string, digits: number) {
		const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)');
		const m = value.toString().match(re);
		return m ? parseFloat(m[1]) : value;
	}

	calcPNLandROE(size: number, markPrice: number, leverage: number, entry_price: number, side: 'LONG' | 'SHORT') {
		let roe: string | number = '-';
		let pnl: string | number = '-';

		if (!Number.isNaN(markPrice)) {
			let margin = (Math.abs(size) / leverage) * entry_price;
			roe = ((markPrice - entry_price) / entry_price) * 100 * leverage;
			if (side === 'SHORT') {
				roe = -1 * parseFloat('' + roe);
			}

			pnl = ((margin * roe) / 100).toFixed(2);
		}

		return { roe, pnl };
	}

	calculateFuturesMax(
		AvblAmount: number | string,
		price: number | null,
		leverage: number | null,
		side: 'SHORT' | 'LONG',
		fee: number = 0.0006,
	) {
		let Max = '0';
		if (price && leverage && AvblAmount) {
			const OpenFee = new Decimal(price).mul(fee).toString();

			const bankruptcy = new Decimal(price)
				.mul(new Decimal(leverage).add(side === 'LONG' ? 1 : -1))
				.div(leverage)
				.toString();

			const closeFee = new Decimal(bankruptcy).mul(fee).toString();

			if (+price === 0) {
				Max = '0';
			} else {
				Max = new Decimal(AvblAmount)
					.div(new Decimal(new Decimal(price).div(leverage).toString()).add(OpenFee).add(closeFee).toString())
					.toFixed(24)
					.toString();
				Max = new Decimal(Max).lte(0) ? '0' : Max;
			}
		}

		return Max;
	}

	calculateFuturesOrderCost(
		quantity: number | string,
		price: string,
		leverage: number,
		side: string,
		sizeType: 'BASE' | 'USDT',
		baseMultiplier: number,
		fee = 0.0006,
	) {
		let cost = 0;
		if (price && quantity && leverage) {
			if (sizeType === 'USDT') {
				if (baseMultiplier) {
					quantity = finance.toFixedDown(new Decimal(quantity).div(price).toString(), baseMultiplier);
				} else {
					quantity = new Decimal(quantity).div(price).toString();
				}
			}

			const initalMargin = new Decimal(price).mul(quantity).div(leverage).toString();
			const OpenFee = new Decimal(quantity).mul(price).mul(fee).toString();

			const bankrapcy = new Decimal(price)
				.mul(new Decimal(leverage).add(side === 'Buy' ? -1 : 1))
				.div(leverage)
				.toString();

			const closeFee = new Decimal(quantity).mul(bankrapcy).mul(fee).toString();

			cost = +new Decimal(initalMargin).add(OpenFee).add(closeFee).toString();
			cost = new Decimal(cost).lte(0) ? 0 : cost;
		}
		return this.toFixedDown(`${cost}`, 2);
	}

	NumberFormatter(val: number | string) {
		var sign = 1;

		// trim the number decimal point if it exists
		let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
		let len = num.toString().length;
		let result = '';
		let count = 1;

		for (let i = len - 1; i >= 0; i--) {
			result = num.toString()[i] + result;
			if (count % 3 === 0 && count !== 0 && i !== 0) {
				result = ',' + result;
			}
			count++;
		}

		// add number after decimal point
		if (val.toString().includes('.')) {
			result = result + '.' + val.toString().split('.')[1];
		}
		// return result with - sign if negative
		return sign < 0 ? '-' + result : result;
	}
}

const finance = new Finance();

export default finance;
