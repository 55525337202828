import { getDesignTokens } from 'common/config/theme';
import { useAppSelector } from 'utils/hooks/redux-helper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FC, ReactNode, useEffect } from 'react';
import CustomNavbar from './custom-navbar';

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
	const theme = useAppSelector(state => state.ThemeProvider.theme);

	const darkModeTheme = createTheme(getDesignTokens(theme));

	useEffect(() => {
		let portrait = window.matchMedia('(orientation: portrait)');

		portrait.addEventListener('change', function (e) {
			window.location.reload();
		});
	}, []);

	useEffect(() => {
		document.querySelector('body')?.setAttribute('data-theme', theme);
	}, [theme]);

	return (
		<ThemeProvider theme={darkModeTheme}>
			<CustomNavbar />
			{children}
		</ThemeProvider>
	);
};

export default MainLayout;
