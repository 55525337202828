import { createSlice } from '@reduxjs/toolkit';
import { ITradingBot } from 'repository/api/dto/bot/bot.dto';

interface IBotStore {
	userBot: ITradingBot[] | null;
}

const initialState: IBotStore = {
	userBot: null,
};

export const Bot = createSlice({
	name: 'Futures',
	initialState: initialState,
	reducers: {
		changeUserBots: (state, action: { payload: ITradingBot[] }) => {
			state.userBot = action.payload;
		},
	},
});

export const { changeUserBots } = Bot.actions;
export default Bot.reducer;

export type { IBotStore };
