import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import arJSON from 'locales/ar';
import deJSON from 'locales/de';
import enJSON from 'locales/en';
import faJSON from 'locales/fa';
import ruJSON from 'locales/ru';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		supportedLngs: ['en', 'fa', 'ar', 'de', 'es', 'ru'],
		resources: {
			en: {
				...enJSON,
			},
			fa: {
				...faJSON,
			},
			ar: {
				...arJSON,
			},
			de: {
				...deJSON,
			},
			ru: {
				...ruJSON,
			},
		},
	});

export default i18n;
