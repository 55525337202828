import RequestHandler from 'utils/requestHandler';

export const getNotification = async (limit = 5, page = 1) => {
	return RequestHandler.call({ url: `/api/v1/notifications?limit=${limit}&page=${page}`, method: 'get' }).then(res => res);
};

export const getAllNotification = async (limit = 10, page = 1) => {
	return RequestHandler.call({ url: `/api/v1/notifications?limit=${limit}&page=${page}`, method: 'get' }).then(res => res.data);
};

export const viewNotification = async id => {
	return RequestHandler.call({ url: `/api/v1/notifications/${id}`, method: 'put', data: { viewed: true } }).then(res => res.data);
};

export const GetAnnouncement = async id => {
	return RequestHandler.call({ url: `/api/v1/announcments?limit=5&page=1`, method: 'get' }).then(res => res.result);
};
