import { createSlice } from '@reduxjs/toolkit';

export const them = createSlice({
	name: 'them',
	initialState: {
		theme: localStorage.getItem('them') ? localStorage.getItem('them') : 'light',
		themeCache: localStorage.getItem('them-cache') ? localStorage.getItem('them-cache') : 'light',
	},
	reducers: {
		themProvider: (state, action) => {
			state.theme = action.payload;
			localStorage.setItem('them', action.payload);
		},
		themeCashProvider: (state, action) => {
			state.themeCache = action.payload;
			localStorage.setItem('them-cache', action.payload);
		},
	},
});

export const { themProvider, themeCashProvider } = them.actions;

export default them.reducer;
