import { createSlice } from '@reduxjs/toolkit';
import finance from 'utils/finance';
import { Layout } from 'react-grid-layout';
import { IFuturesStore } from '../types';
import { LeftOrderBookLayouts, RightOrderBookLayouts } from '../layout';

const initialState: IFuturesStore = {
	coinListVisible: false,
	favorites: JSON.parse(localStorage.getItem('futuresFavorites') ?? '["BTCUSDT", "ETHUSDT", "BNBUSDT"]'),
	tour: {
		mainTour: localStorage.getItem('isTourCompleted') ? (localStorage.getItem('isTourCompleted') === 'true' ? true : false) : false,
		v6Tour: localStorage.getItem('futures-tour') ? JSON.parse(localStorage.getItem('futures-tour')!).v6Tour : false,
	},
	pairsSetting: [],
	layout: localStorage.getItem('futures-layout')
		? (JSON.parse(localStorage.getItem('futures-layout')!) as { [p: string]: Layout[] })
		: RightOrderBookLayouts,
	actionSetting: {
		lastPrice: null,
	},
	preferences: {
		isVisible: false,
		isPositionModeVisible: false,
		confirmationPopUp: {
			orders: localStorage.getItem('futures-preferences')
				? JSON.parse(localStorage.getItem('futures-preferences')!).confirmationPopUp.orders
				: false,
			reverse: localStorage.getItem('futures-preferences')
				? JSON.parse(localStorage.getItem('futures-preferences')!).confirmationPopUp.reverse
				: true,
		},
		layout: {
			chart: localStorage.getItem('futures-preferences')
				? JSON.parse(localStorage.getItem('futures-preferences')!).layout.chart
				: true,
			orderbook: localStorage.getItem('futures-preferences')
				? JSON.parse(localStorage.getItem('futures-preferences')!).layout.orderbook
				: true,
			mode: localStorage.getItem('futures-preferences')
				? JSON.parse(localStorage.getItem('futures-preferences')!).layout.mode
				: 'RIGHT_ORDERBOOK',
		},
	},
	addBotStatus: { status: false, type: 'ADD' },
	pairs: [],
	ping: 0,
	pairsConfig: [],
	orderBook: {
		sells: [],
		buys: [],
		setting: {
			biggestItem: 1,
			view: 'ALL',
			precision: 'default',
		},
	},
	depth: { sell: [], buy: [] },
	positions: [],
	botPositions: [],
	recentTrades: [],
	openOrders: null,
	currentPair: null,
	balance: {
		futures: null,
		spot: null,
	},
	kline: [],
	chartSetting: {
		interval: '1',
		viewStatus: 'NORMAL',
		type: 'STANDARD',
		priceType: 'LAST',
		resizeTrigger: false,
		blockPointerEventTradingView: false,
		expandedViewTopMargin: 32,
		expandedViewLeftMargin: 0,
	},
};

export const Futures = createSlice({
	name: 'Futures',
	initialState: initialState,
	reducers: {
		changeCoinListVisibility: (state, action) => {
			state.coinListVisible = action.payload;
		},
		AddToFavorties: (state, action) => {
			var prev: string[] = JSON.parse(localStorage.getItem('futuresFavorites') ?? '["BTCUSDT", "ETHUSDT", "BNBUSDT"]');
			if (!prev.includes(action.payload)) {
				prev.push(action.payload);
				localStorage.setItem('futuresFavorites', JSON.stringify(prev));
				state.favorites = prev;
			}
		},
		RemoveFromFavorties: (state, action) => {
			var prev: string[] = JSON.parse(localStorage.getItem('futuresFavorites') ?? '["BTCUSDT", "ETHUSDT", "BNBUSDT"]');
			if (prev.includes(action.payload)) {
				prev = prev.filter(item => item !== action.payload);
				localStorage.setItem('futuresFavorites', JSON.stringify(prev));
				state.favorites = prev;
			}
		},

		ChangePairsSetting: (state, action) => {
			if (action.payload.type === 'init') {
				let pairs = action.payload.data;
				let settings = [];
				let statePairs = [];
				for (const pair of pairs) {
					let baseMultiplier = 4;
					if (pair?.qtyStep?.toString().includes('.')) {
						baseMultiplier = pair?.qtyStep?.toString().split('.')[1].length;
					} else {
						baseMultiplier = 0;
					}
					let quoteMultiplier = 4;
					if (pair?.tickSize?.toString().includes('.')) {
						quoteMultiplier = pair?.tickSize?.toString().split('.')[1].length;
					} else {
						quoteMultiplier = 0;
					}
					settings.push({
						base: pair.baseCoin,
						quote: pair.quoteCoin,
						symbol: pair.symbol,
						tickSize: pair.tickSize,
						qtyStep: pair.qtyStep,
						baseMultiplier,
						quoteMultiplier,
						minLeverage: pair.minLeverage,
						maxLeverage: +finance.toFixedDown(pair.maxLeverage, 0),
					});

					statePairs.push({
						base: pair.baseCoin,
						quote: pair.quoteCoin,
						symbol: pair.symbol,
						lastPrice: +pair.lastPrice,
						change: +pair.price24hPercentage,
						// markPrice: pair.markPrice,
						turnover24h: +pair.turnover24h,
					});
				}

				document.dispatchEvent(new CustomEvent('chart-pair-setting', { detail: settings }));
				state.pairsSetting = settings;
				state.pairs = statePairs;
			} else if (action.payload.type === 'update') {
				let prevState = state.pairs;
				for (const update of action.payload.body) {
					let findIndex = prevState.findIndex(item => item.symbol === update.symbol);
					if (findIndex !== -1) {
						prevState[findIndex].change = update.price24hPcnt;
						prevState[findIndex].lastPrice = update.lastPrice;
						prevState[findIndex].turnover24h = update.turnover24h;
						prevState[findIndex].markPrice = update.markPrice;
					}
				}
				document.dispatchEvent(new CustomEvent('chart-position-mark-prices', { detail: prevState }));
				state.pairs = prevState;
			}
		},

		changeAddBotStatus: (state, action: { payload: { status: boolean; type: 'ADD' | 'EDIT'; id?: number } }) => {
			state.addBotStatus = action.payload;
		},

		changeOrderBook: (state, action) => {
			if (action.payload.type === 'update') {
				let data = action.payload.body;
				state.orderBook = {
					buys: data.buys,
					sells: data.sells,
					setting: {
						biggestItem: data.biggestItem,
						precision: state.orderBook.setting.precision,
						view: state.orderBook.setting.view,
					},
				};
			} else if (action.payload.type === 'precision') {
				state.orderBook.setting.precision = action.payload.body;
			} else if (action.payload.type === 'view') {
				state.orderBook.setting.view = action.payload.body;
			}
		},
		changeDepth: (state, action) => {
			state.depth = action.payload;
		},

		changeCurrentPair: (state, action) => {
			if (action.payload.type === 'update') {
				const { price24hPcnt, ...data } = action.payload.body;
				if (state.currentPair === null) {
					let _price24hPcnt = +parseFloat('' + +price24hPcnt * 100).toFixed(2);
					state.currentPair = { ...data, price24hPcnt: _price24hPcnt };
				} else {
					let _price24hPcnt = price24hPcnt ? +parseFloat('' + +price24hPcnt * 100).toFixed(2) : null;

					state.currentPair = {
						...state.currentPair,
						...data,
						...(_price24hPcnt && { price24hPcnt: _price24hPcnt }),
					};
				}
				if (state.actionSetting.lastPrice === null) {
					state.actionSetting.lastPrice = data.lastPrice;
				}
			} else if (action.payload.type === 'reset') {
				state.currentPair = null;
				state.actionSetting.lastPrice = null;
			}
		},

		changePing: (state, action) => {
			state.ping = action.payload;
		},

		changeRecentTrades: (state, action) => {
			const symbol = state.currentPair?.symbol;
			const config = state.pairsConfig.find(item => item.symbol === symbol);
			const setting = state.pairsSetting.find(item => item.symbol === symbol);

			let data = action.payload.body
				.map((item: any) => {
					const amount = config
						? parseFloat(`${(+config.vrp * +item.size) / 100}`).toFixed(setting?.baseMultiplier ?? 1)
						: item.size;
					if (+amount > 0) {
						return {
							...item,
							size: amount,
						};
					} else {
						return null;
					}
				})
				.filter((item: any) => item !== null);

			if (action.payload.type === 'init') {
				state.recentTrades = data;
			} else {
				state.recentTrades = [...data, ...state.recentTrades].slice(0, 28);
			}
		},

		changeActionLastPrice: (state, action) => {
			state.actionSetting.lastPrice = action.payload;
		},

		changeBalanceInfo: (state, action) => {
			if (action.payload.type === 'init') {
				if (action.payload.wallet === 'futures') {
					state.balance.futures = action.payload.data;
				}
			}
		},

		changePositions: (state, action) => {
			if (action.payload.type === 'init') {
				state.positions = action.payload.data;

				document.dispatchEvent(new CustomEvent('chart-positions', { detail: action.payload.data }));
			} else {
				let positions = state.positions;
				let update = action.payload.data;
				for (const positionItem of update) {
					let index = positions.findIndex(
						item => item.symbol === positionItem.symbol && item.positionIdx === positionItem.positionIdx,
					);
					if (index !== -1) {
						if (positionItem.positionStatus === 'Normal') {
							positions[index].bustPrice = positionItem.bustPrice;
							positions[index].autoAddMargin = positionItem.autoAddMargin;
							positions[index].cumRealisedPnl = positionItem.cumRealisedPnl;
							positions[index].avgPrice = positionItem.entryPrice ?? positionItem.avgPrice;
							positions[index].leverage = positionItem.leverage;
							positions[index].liqPrice = positionItem.liqPrice;
							positions[index].positionValue = positionItem.positionValue;
							positions[index].positionBalance = positionItem.positionBalance;
							positions[index].side = positionItem.side;
							positions[index].size = positionItem.size;
							positions[index].stopLoss = positionItem.stopLoss;
							positions[index].takeProfit = positionItem.takeProfit;
							positions[index].tradeMode = positionItem.tradeMode;
							positions[index].trailingStop = positionItem.trailingStop;
							positions[index].unrealisedPnl = positionItem.unrealisedPnl;
						} else {
							positions.splice(index, 1);
						}
					} else {
						if (positionItem.positionStatus === 'Normal') {
							positions.push({ ...positionItem, avgPrice: positionItem.entryPrice });
						}
					}
				}
				state.positions = positions;
				document.dispatchEvent(new CustomEvent('chart-positions', { detail: positions }));
			}
		},
		changeBotPositions: (state, action) => {
			if (action.payload.type === 'init') {
				state.botPositions = action.payload.data;
			} else {
				let positions = state.botPositions;
				let position = action.payload.data;

				for (const positionItem of position) {
					let index = positions.findIndex(item => item.symbol === positionItem.symbol);
					if (index !== -1) {
						positions[index].bustPrice = positionItem.bustPrice;
						positions[index].autoAddMargin = positionItem.autoAddMargin;
						positions[index].cumRealisedPnl = positionItem.cumRealisedPnl;
						positions[index].avgPrice = positionItem.entryPrice;
						positions[index].leverage = positionItem.leverage;
						positions[index].liqPrice = positionItem.liqPrice;
						positions[index].positionValue = positionItem.positionValue;
						positions[index].positionBalance = positionItem.positionBalance;
						positions[index].side = positionItem.side;
						positions[index].size = positionItem.size;
						positions[index].stopLoss = positionItem.stopLoss;
						positions[index].takeProfit = positionItem.takeProfit;
						positions[index].tradeMode = positionItem.tradeMode;
						positions[index].trailingStop = positionItem.trailingStop;
						positions[index].unrealisedPnl = positionItem.unrealisedPnl;
					} else {
						state.botPositions.push({ ...positionItem, avgPrice: positionItem.entryPrice });
					}
				}
			}
		},

		changeOpenOrders: (state, action) => {
			if (action.payload.type === 'init') {
				state.openOrders = action.payload.data;
				document.dispatchEvent(new CustomEvent('chart-orders', { detail: action.payload.data }));
			} else {
				for (const order of action.payload.data) {
					// new for all new orders | Untriggered for new stop limit
					if (order.orderStatus === 'New' || order.orderStatus === 'Untriggered') {
						let Orders = state.openOrders!;
						let index = state.openOrders!.findIndex(item => item.orderId === order.orderId);
						if (index !== -1) {
							Orders[index].symbol = order.symbol;
							Orders[index].price = order.price;
							Orders[index].qty = order.qty;
							Orders[index].side = order.side;
							Orders[index].triggerPrice = order.triggerPrice;
							Orders[index].orderType = order.orderType;
							Orders[index].cumExecQty = order.cumExecQty;
							Orders[index].reduceOnly = order.reduceOnly;
							Orders[index].takeProfit = order.takeProfit;
							Orders[index].stopLoss = order.stopLoss;
							Orders[index].triggerBy = order.triggerBy;
							Orders[index].stopOrderType = order.stopOrderType;
							Orders[index].triggerDirection = order.triggerDirection;
							Orders[index].orderStatus = order.orderStatus;
						} else {
							let data = {
								orderId: order.orderId,
								symbol: order.symbol,
								price: order.price,
								qty: order.qty,
								side: order.side,
								triggerPrice: order.triggerPrice,
								orderType: order.orderType,
								cumExecQty: order?.cumExecQty,
								positionIdx: order.positionIdx,
								reduceOnly: order.reduceOnly,
								takeProfit: order.takeProfit,
								stopLoss: order.stopLoss,
								triggerBy: order.triggerBy,
								stopOrderType: order.stopOrderType,
								triggerDirection: order.triggerDirection,
								createdTime: order.createdTime,
								orderStatus: order.orderStatus,
							};
							Orders?.push(data);
							Orders = Orders?.sort((a, b) => +b.createdTime - +a.createdTime);
						}
						state.openOrders = Orders;
						document.dispatchEvent(new CustomEvent('chart-orders', { detail: Orders }));
					} else if (order.orderStatus === 'Cancelled' || order.orderStatus === 'Deactivated' || order.orderStatus === 'Filled') {
						const orders = state.openOrders!?.filter(item => item.orderId !== order.orderId);
						document.dispatchEvent(new CustomEvent('chart-orders', { detail: orders }));
						state.openOrders = orders;
					} else if (order.orderStatus === 'PartiallyFilled') {
						let index = state.openOrders!.findIndex(item => item.orderId === order.orderId);
						const orders = state.openOrders;
						orders![index].cumExecQty = order?.cumExecQty;
						document.dispatchEvent(new CustomEvent('chart-orders', { detail: orders }));
						state.openOrders = orders;
					}
				}
			}
		},

		changeTourStatus: (state, action) => {
			//main_tour
			state.tour.mainTour = action.payload;
			localStorage.setItem('isTourCompleted', action.payload);

			// v6_tour
			let tour = localStorage.getItem('futures-tour') ? JSON.parse(localStorage.getItem('futures-tour')!) : {};
			tour.v6Tour = action.payload;
			state.tour.v6Tour = action.payload;
			localStorage.setItem('futures-tour', JSON.stringify(tour));
		},

		changePairsConfig: (state, action) => {
			state.pairsConfig = action.payload;
		},
		changeKline: (state, action) => {
			document.dispatchEvent(new CustomEvent('socket-kline', { detail: action.payload }));
			state.kline = action.payload;
		},
		changeChartSetting: (state, action: { payload: IFuturesStore['chartSetting'] }) => {
			state.chartSetting = action.payload;
		},
		changeChartSettingTrigger: (state, action) => {
			state.chartSetting.resizeTrigger = action.payload.resizeTrigger;
		},
		changeLayout: (state, action: { payload: IFuturesStore['layout'] }) => {
			state.layout = action.payload;
			localStorage.setItem('futures-layout', JSON.stringify(action.payload));
		},
		resetLayout: (state, action) => {
			state.layout = RightOrderBookLayouts;
			state.preferences.layout.mode = 'RIGHT_ORDERBOOK';
		},
		changePreferencesVisibility: (state, action: { payload: boolean }) => {
			state.preferences.isVisible = action.payload;
		},
		changePositionModeVisibility: (state, action: { payload: boolean }) => {
			state.preferences.isPositionModeVisible = action.payload;
		},
		changePreferencesSettings: (
			state,
			action: {
				payload: {
					confirmationPopUp: IFuturesStore['preferences']['confirmationPopUp'];
					layout: IFuturesStore['preferences']['layout'];
				};
			},
		) => {
			if (state.preferences.layout.mode !== action.payload.layout.mode) {
				if (action.payload.layout.mode === 'LEFT_ORDERBOOK') {
					state.layout = LeftOrderBookLayouts;
					localStorage.setItem('futures-layout', JSON.stringify(LeftOrderBookLayouts));
				} else {
					state.layout = RightOrderBookLayouts;
					localStorage.setItem('futures-layout', JSON.stringify(RightOrderBookLayouts));
				}
			}
			state.preferences.layout = action.payload.layout;

			state.preferences.confirmationPopUp = action.payload.confirmationPopUp;
			localStorage.setItem('futures-preferences', JSON.stringify(action.payload));
		},
	},
});

export const {
	changeCoinListVisibility,
	AddToFavorties,
	RemoveFromFavorties,
	ChangePairsSetting,
	changeOrderBook,
	changeCurrentPair,
	changePing,
	changeRecentTrades,
	changeActionLastPrice,
	changeBalanceInfo,
	changePositions,
	changeOpenOrders,
	changeTourStatus,
	changePairsConfig,
	changeBotPositions,
	changeAddBotStatus,
	changeKline,
	changeChartSetting,
	changeLayout,
	resetLayout,
	changeChartSettingTrigger,
	changePreferencesVisibility,
	changePreferencesSettings,
	changePositionModeVisibility,
	changeDepth,
} = Futures.actions;
export default Futures.reducer;
