import { createSlice } from '@reduxjs/toolkit';

export const Loader = createSlice({
	name: 'loader',
	initialState: {
		LoaderStatus: false,
	},
	reducers: {
		loader: (state, action) => {
			state.LoaderStatus = action.payload;
		},
	},
});

export const { loader } = Loader.actions;
export default Loader.reducer;
