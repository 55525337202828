const RightOrderBookLayouts = {
	lg: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 40, h: 2, static: true },
		{ i: 'actions', x: 40, y: 1, w: 8, h: 20, static: true },
		{ i: 'market-info', x: 32, y: 3, w: 8, h: 18, minH: 10, minW: 6 },
		{ i: 'chart', x: 0, y: 3, w: 32, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 40, h: 11, minH: 8, minW: 26, maxH: 24 },
		{ i: 'assets', x: 40, y: 21, w: 8, h: 6, static: true },
		{ i: 'margin', x: 40, y: 27, w: 8, h: 5, static: true },
	],
	md: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 39, h: 2, static: true },
		{ i: 'actions', x: 39, y: 1, w: 9, h: 20, static: true },
		{ i: 'market-info', x: 30, y: 3, w: 9, h: 18, minH: 10, minW: 7 },
		{ i: 'chart', x: 0, y: 3, w: 30, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 39, h: 11, minH: 8, minW: 30, maxH: 24 },
		{ i: 'assets', x: 39, y: 21, w: 9, h: 6, static: true },
		{ i: 'margin', x: 39, y: 27, w: 9, h: 5, static: true },
	],
	sm: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 38, h: 2, static: true },
		{ i: 'actions', x: 38, y: 1, w: 10, h: 20, static: true },
		{ i: 'market-info', x: 28, y: 3, w: 10, h: 18, minH: 10, minW: 8 },
		{ i: 'chart', x: 0, y: 3, w: 28, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 38, h: 11, minH: 8, minW: 32, maxH: 24 },
		{ i: 'assets', x: 38, y: 21, w: 10, h: 6, static: true },
		{ i: 'margin', x: 38, y: 27, w: 10, h: 5, static: true },
	],
	xs: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 37, h: 2, static: true },
		{ i: 'actions', x: 37, y: 1, w: 11, h: 20, static: true },
		{ i: 'market-info', x: 26, y: 3, w: 11, h: 18, minH: 10, minW: 9 },
		{ i: 'chart', x: 0, y: 3, w: 26, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 37, h: 11, minH: 8, minW: 37, maxH: 24 },
		{ i: 'assets', x: 37, y: 21, w: 11, h: 6, static: true },
		{ i: 'margin', x: 37, y: 27, w: 11, h: 5, static: true },
	],
};
const LeftOrderBookLayouts = {
	lg: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 40, h: 2, static: true },
		{ i: 'actions', x: 40, y: 1, w: 8, h: 20, static: true },
		{ i: 'market-info', x: 0, y: 3, w: 8, h: 18, minH: 10, minW: 6 },
		{ i: 'chart', x: 8, y: 3, w: 32, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 40, h: 11, minH: 8, minW: 26, maxH: 24 },
		{ i: 'assets', x: 40, y: 21, w: 8, h: 6, static: true },
		{ i: 'margin', x: 40, y: 27, w: 8, h: 5, static: true },
	],
	md: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 39, h: 2, static: true },
		{ i: 'actions', x: 39, y: 1, w: 9, h: 20, static: true },
		{ i: 'market-info', x: 0, y: 3, w: 9, h: 18, minH: 10, minW: 7 },
		{ i: 'chart', x: 9, y: 3, w: 30, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 39, h: 11, minH: 8, minW: 30, maxH: 24 },
		{ i: 'assets', x: 39, y: 21, w: 9, h: 6, static: true },
		{ i: 'margin', x: 39, y: 27, w: 9, h: 5, static: true },
	],
	sm: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 38, h: 2, static: true },
		{ i: 'actions', x: 38, y: 1, w: 10, h: 20, static: true },
		{ i: 'market-info', x: 0, y: 3, w: 10, h: 18, minH: 10, minW: 8 },
		{ i: 'chart', x: 10, y: 3, w: 28, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 38, h: 11, minH: 8, minW: 32, maxH: 24 },
		{ i: 'assets', x: 38, y: 21, w: 10, h: 6, static: true },
		{ i: 'margin', x: 38, y: 27, w: 10, h: 5, static: true },
	],
	xs: [
		{ i: 'fav', x: 0, y: 0, w: 48, h: 1, static: true },
		{ i: 'header', x: 0, y: 1, w: 37, h: 2, static: true },
		{ i: 'actions', x: 37, y: 1, w: 11, h: 20, static: true },
		{ i: 'market-info', x: 0, y: 3, w: 11, h: 18, minH: 10, minW: 9 },
		{ i: 'chart', x: 11, y: 3, w: 26, h: 18, minH: 11, minW: 20 },
		{ i: 'histories', x: 0, y: 21, w: 37, h: 11, minH: 8, minW: 37, maxH: 24 },
		{ i: 'assets', x: 37, y: 21, w: 11, h: 6, static: true },
		{ i: 'margin', x: 37, y: 27, w: 11, h: 5, static: true },
	],
};

export { RightOrderBookLayouts, LeftOrderBookLayouts };
