import LoadingAnimation from 'assets/json/loader.json';
import Lottie from 'react-lottie-player';

//assets
import { LoaderTMP } from 'assets/css/layout/loader';

const Loader = () => {
	return (
		<LoaderTMP>
			<Lottie loop animationData={LoadingAnimation} play style={{ width: 250, height: 250 }} />
		</LoaderTMP>
	);
};

export default Loader;
